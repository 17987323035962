@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import 'assets/scss/icons';

@import 'ngx-lightbox/lightbox.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'flatpickr/dist/flatpickr.css';
@import 'leaflet/dist/leaflet.css';
@import '@ctrl/ngx-emoji-mart/picker';
@import 'ngx-ui-switch/ui-switch.component';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

@import 'assets/scss/variables';
@import 'bootstrap/scss/bootstrap';

@import 'assets/scss/utilities';

@import 'assets/scss/custom/components/reboot';
@import 'assets/scss/custom/components/backgrounds';
@import 'assets/scss/custom/components/badge';
@import 'assets/scss/custom/components/buttons';
@import 'assets/scss/custom/components/breadcrumb';
@import 'assets/scss/custom/components/card';
@import 'assets/scss/custom/components/dropdown';
@import 'assets/scss/custom/components/nav';
@import 'assets/scss/custom/components/table';
@import 'assets/scss/custom/components/pagination';
@import 'assets/scss/custom/components/progress';

//@import "assets/scss/custom/fonts/fonts";
@import 'bootstrap/scss/mixins.scss';

@import 'assets/scss/theme-light';
//@import "assets/scss/theme-dark";

@import 'assets/scss/custom/structure/general';
@import 'assets/scss/custom/structure/topbar';
@import 'assets/scss/custom/structure/page-head';
@import 'assets/scss/custom/structure/footer';
@import 'assets/scss/custom/structure/right-sidebar';
@import 'assets/scss/custom/structure/vertical';
@import 'assets/scss/custom/structure/horizontal-nav';
@import 'assets/scss/custom/structure/layouts';

@import 'assets/scss/custom/components/waves';
@import 'assets/scss/custom/components/avatar';
@import 'assets/scss/custom/components/accordion';
@import 'assets/scss/custom/components/helper';
@import 'assets/scss/custom/components/preloader';
@import 'assets/scss/custom/components/forms';
@import 'assets/scss/custom/components/widgets';
//@import "assets/scss/custom/components/demos";
@import 'assets/scss/custom/components/print';

@import 'assets/scss/custom/plugins/custom-scrollbar';
@import 'assets/scss/custom/plugins/calendar';
@import 'assets/scss/custom/plugins/calendar-full';
//@import 'assets/scss/custom/plugins/dragula';
//@import 'assets/scss/custom/plugins/session-timeout';
//@import 'assets/scss/custom/plugins/range-slider';
//@import 'assets/scss/custom/plugins/sweatalert2';
@import 'assets/scss/custom/plugins/rating';
@import 'assets/scss/custom/plugins/toastr';
@import 'assets/scss/custom/plugins/parsley';
@import 'assets/scss/custom/plugins/select2';

@import 'assets/scss/custom/plugins/switch';
@import 'assets/scss/custom/plugins/colorpicker';
@import 'assets/scss/custom/plugins/timepicker';
@import 'assets/scss/custom/plugins/datepicker';
@import 'assets/scss/custom/plugins/bootstrap-touchspin';
@import 'assets/scss/custom/plugins/form-editors';
@import 'assets/scss/custom/plugins/form-upload';
@import 'assets/scss/custom/plugins/form-wizard';
@import 'assets/scss/custom/plugins/datatable';
@import 'assets/scss/custom/plugins/responsive-table';
@import 'assets/scss/custom/plugins/table-editable';
//@import 'assets/scss/custom/plugins/apexcharts';
//@import 'assets/scss/custom/plugins/echarts';
//@import 'assets/scss/custom/plugins/flot';
//@import 'assets/scss/custom/plugins/sparkline-chart';
//@import 'assets/scss/custom/plugins/google-map';
//@import 'assets/scss/custom/plugins/vector-maps';
//@import 'assets/scss/custom/plugins/leaflet-maps';
@import 'assets/scss/custom/plugins/archwizard';
@import 'assets/scss/custom/plugins/bs-datepicker';

@import 'assets/scss/custom/pages/authentication';
@import 'assets/scss/custom/pages/projects';
@import 'assets/scss/custom/pages/contacts';
@import 'assets/scss/custom/pages/email';

@import 'assets/scss/custom';
