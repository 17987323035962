//
// _helper.scss
//

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-21 {
  font-size: 21px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-29 {
  font-size: 28px !important;
}

.font-size-34 {
  font-size: 34px !important;
}

.font-size-36 {
  font-size: 36px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

.font-size-60 {
  font-size: 60px !important;
}

.cursor-pointer {
  cursor: pointer;
}

// Font weight help class

.fw-medium {
  font-weight: $fw-medium;
}

// Social

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 4px);
  display: block;
  border: 2px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
  transition: all 0.4s;

  &:hover {
    color: $gray-600;
    background-color: $gray-200;
  }
}


.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

.h-xxl {
  min-height: 500px;
}


.mb-30 {
  margin-bottom: 30px;
}

.mr-8 {
  margin-right: 8px;
}

// alert

.alert-dismissible {
  .btn-close {
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
    background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
  }
}

[data-layout-mode="dark"] {
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }

  .btn-close-white {
    filter: invert(1) grayscale(100%) brightness(0);
  }
}


// margins

.margin-bottom-50 {
  margin-bottom: 50px;
}

.line-height-0{
  line-height: 0;
}
